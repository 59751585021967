import { extendTheme } from "@chakra-ui/react";

const colors = {
  black: "rgb(0,0,0)",
  blue: "rgb(3,0,170)",
  green: "rgb(27,170,2)",
  cyan: "rgb(28,170,170)",
  red: "rgb(170,0,1)",
  magenta: "rgb(171,1,169)",
  brown: "rgb(170,86,1)",
  white: "rgb(170,170,170)",
  gray: "rgb(85,85,85)",
  lightBlue: "rgb(85,85,255)",
  lightGreen: "rgb(85,255,85)",
  lightCyan: "rgb(85,255,255)",
  lightRed: "rgb(252,85,85)",
  lightMagenta: "rgb(252,85,255)",
  yellow: "rgb(255,255,85)",
  brightWhite: "rgb(255,255,255)",
};

export default extendTheme({
  colors,
  fonts: {
    heading: "VT323",
    body: "VT323",
  },
});
