import React from "react";
import { Box, BoxProps, Flex } from "@chakra-ui/layout";

export default function Card(props: BoxProps & { title: string }) {
  return (
    <Flex
      w="sm"
      minH="sm"
      pos="relative"
      direction="column"
      bgColor="brightWhite"
      outline="5px solid"
      outlineColor="blue"
      outlineOffset="-11px"
      m={4}
      overflow="hidden"
      _before={{
        content: `""`,
        pos: "absolute",
        top: 0,
        left: 0,
        w: "100%",
        h: "100%",
        outline: "5px solid",
        outlineColor: "blue",
        outlineOffset: "-3px",
      }}
      _after={{
        pos: "absolute",
        top: "-9px",
        left: 4,
        fontSize: "19.5px",
        color: "blue",
        bgColor: "brightWhite",
        content: `"${props.title}"`,
        h: "20px",
        textTransform: "uppercase",
        fontWeight: "bold",
      }}
      {...props}
    >
      <Box p={4} overflow="hidden">
        {props.children}
      </Box>
    </Flex>
  );
}
