import { Box, Flex, Stack, Text } from "@chakra-ui/layout";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import useSound from "use-sound";
import Card from "./components/Card";
import ConsoleInput from "./components/ConsoleInput";
import useDate from "./hooks/useDate";
import useFps from "./hooks/useFps";
import { random } from "./utils/random";

(window as any).renderTextWindow = (...args: any[]) => {
  (window as any).EventBus.dispatch("renderText", window, ...args);
};

(window as any).close = (...args: any[]) => {
  (window as any).EventBus.dispatch("closeWindow", window, ...args);
};

(window as any).gw = "are you for real?!?!? that's not funny at all";

// TODO remove shortcuts from input

export default function App() {
  const [playBackgroundSong, audioPlayerData] = useSound("background-song.mp3", {
    volume: 0.1,
    interrupt: true,
  });
  const [consoleLogs, setConsoleLogs] = useState<string[]>([]);
  const [windows, setWindows] = useState<{ id: number; content: any }[]>([]);
  const { avgFps } = useFps(20);
  const { date, time, wish } = useDate();
  const logsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const callback = (event: any, ...params: any[]) => {
      switch (event.type) {
        case "renderText": {
          setWindows((prev) => [...prev, { id: random(1, 9999), content: { text: params[0] } }]);
          break;
        }
        case "closeWindow": {
          setWindows((prev) => prev.filter((w) => w.id !== params[0]));
          break;
        }
      }
    };


    const events = ["renderText", "closeWindow"];
    events.forEach((singleEventName) => {
      (window as any).EventBus.addEventListener(singleEventName, callback);
    });

    return () => {
      events.forEach((singleEventName) => {
        (window as any).EventBus.removeEventListener(singleEventName, callback);
      });
    };
  }, []);

  useLayoutEffect(() => {
    const curr = logsRef.current;
    curr?.scrollTo({ behavior: "smooth", top: curr.scrollHeight });
  }, [consoleLogs.length]);

  useEffect(() => {
    if (!audioPlayerData.isPlaying) {
      playBackgroundSong();
    }
  }, [audioPlayerData.isPlaying, playBackgroundSong]);

  const onConsoleSubmit = (v: string) => {
    const tokenizedValue = v.split(" ");

    let evalResult = "";
    try {
      evalResult = JSON.stringify(eval(tokenizedValue.join(" ")));
    } catch (e) {
      evalResult = JSON.stringify(e.message);
    }
    setConsoleLogs((prev) => [...prev, evalResult]);
  };

  return (
    <Flex userSelect="none" direction="column" className="crt-display">
      <Stack isInline alignItems="center" h={5} w="full" bgColor="blue">
        <Text color="brightWhite">{date}</Text>
        <Text color="brightWhite">{time}</Text>
        <Text color="brightWhite">FPS: {avgFps}</Text>
        <Flex ml="auto !important">
          <Text color="brightWhite">{wish}</Text>
        </Flex>
      </Stack>
      <Card title="Console" h="sm" w="xl">
        <Box pos="relative" display="flex" flexDirection="column" w="full">
          <Flex
            pos="relative"
            ref={logsRef}
            direction="column"
            overflowY="scroll"
            maxH="21rem"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {consoleLogs.map((singleLog, idx) => (
              <Text lineHeight={0.8} color="blue" key={idx}>
                {singleLog}
              </Text>
            ))}
          </Flex>
        </Box>
        <ConsoleInput onSubmit={onConsoleSubmit} />
      </Card>
      {windows.map((singleWindow, idx) => (
        <Card
          pos="absolute"
          top={100 + idx * 15 + "px"}
          title={`${singleWindow.id}`}
          key={singleWindow.id}
          minH={20}
        >
          <Text color="red">{singleWindow.content.text}</Text>
        </Card>
      ))}
    </Flex>
  );
}
