import React, { FormEvent, useRef, useState } from "react";
import { Input } from "@chakra-ui/input";
import { Box, BoxProps, Flex, Text } from "@chakra-ui/layout";
import { keyframes } from "@chakra-ui/system";
import theme from "../theme";

interface ConsoleInputProps extends Omit<BoxProps, "onSubmit"> {
  onSubmit: (v: string) => void;
}

export default function ConsoleInput(props: ConsoleInputProps) {
  const { onSubmit, ...boxProps } = props;
  const [hasFocus, setHasFocus] = useState(false);
  const [result, setResult] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef<any>(null);
  const formRef = useRef<any>(null);

  const onClick = () => {
    (inputRef.current as any).focus();
  };

  const onChange = (e: any) => {
    const v = e.target.value;
    setResult(e.target.value);
    const first: any[] = e.target.value.split("");
    const second = result.split("");
    const firstDifferenceIndex = first.findIndex((v: any, idx: any) => v !== second[idx]);
    if (firstDifferenceIndex !== -1) {
      setCursorPosition(firstDifferenceIndex + 1);
    } else {
      setCursorPosition((prev) => prev - 1);
    }
  };

  const onKeyDown = (e: any) => {
    const keyName = e.key;
    const targetLength = e.target.value.length;
    const isArrowLeft = keyName === "ArrowLeft";
    const isArrowRight = keyName === "ArrowRight";

    if (isArrowLeft && cursorPosition > 0) {
      setCursorPosition((prev) => prev - 1);
      return;
    }

    if (isArrowRight && cursorPosition + 1 < targetLength) {
      setCursorPosition((prev) => prev + 1);
      return;
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    formRef.current.reset();
    setCursorPosition(0);
    setResult("");
    onSubmit(result);
  };

  return (
    <Flex
      as="form"
      cursor="text"
      position="relative"
      onClick={onClick}
      onSubmit={handleSubmit}
      ref={formRef}
      {...boxProps}
    >
      <Text color="blue">&gt;</Text>
      <Input
        ref={inputRef}
        pos="absolute"
        variant="unstyled"
        color="blue"
        justifySelf="flex-end"
        style={{ caretColor: "transparent" }}
        onKeyDown={onKeyDown}
        onChange={onChange}
        autoCorrect="false"
        spellCheck="false"
        left="-1000px"
        cursor="text"
        overflow="hidden"
        bgColor="red"
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
      />
      <Box>{result}</Box>
      <Box
        sx={{
          display: "block",
          position: "absolute",
          content: `""`,
          left: (cursorPosition + 1) * 6.5 + "px",
          top: "50%",
          transform: "translateY(-50%)",
          width: "6px",
          height: 4,
          margin: "auto",
          verticalAlign: "middle",
          bgColor: "black",
          animation: hasFocus ? `${blinker} 1s step-start infinite` : "none",
        }}
      />
    </Flex>
  );
}

const blinker = keyframes`
  0% {
    background-color: ${theme.colors.black};
  }

  50% {
    background-color: ${theme.colors.yellow};
  }
`;
